<template>
  <div class="clearfix w-100 pb-5">
    <div class="container text-white py-5 text-center" v-if="state==='loading'">
      <div-loader />
    </div>
    <div class="container py-5 text-center" v-else-if="state==='error'">
      <error-div :message="errorMessage" />
    </div>
    <div class="container py-5" v-else>
      <div class="form-row py-4">
        <div class="col-12 text-center text-white mb-5">
          <h3><b>{{category.category}}</b></h3>
          <div class="w-100"></div>
          <span>{{category.info}}</span>
        </div>
        <single-ticket v-for="(ticket,index) in tickets"
                       :ticket="ticket" :key="'sing'+index" />
      </div>
    </div>
  </div>
</template>

<script>
import DivLoader from "@/components/Template/DivLoader";
import ErrorDiv from "@/components/Template/ErrorDiv";
import SingleTicket from "@/components/Home/SingleTicket";
export default {
  name: "TicketCategory",
  components: {SingleTicket, ErrorDiv, DivLoader},
  created() {
    this.loadTickets();
  },
  methods : {
    loadTickets() {
      const code = this.$route.params.code;
      this.state = 'loading';
      this.$http.get("front/ticket/"+code).then(response=>{
        if(response.data.error===undefined) {
          this.state = 'error';
          this.errorMessage = this.$t('errors.unableToLoadTickets');
        } else if(response.data.error) {
          this.state = 'error';
          this.errorMessage = response.data.message;
        } else {
          this.state = 'success';
          this.tickets = response.data.tickets;
          this.category = response.data.category;
        }
      }).catch(error=>{
        this.state = 'error';
        this.errorMessage = error.toString();
      });
    }
  },
  data() {
    return {
      state : 'loading',
      errorMessage : "",
      tickets : [],
      category : {},
    }
  }
}
</script>

<style scoped>

</style>